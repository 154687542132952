.App {
  max-width: 750px; /* approximately Readme.com's max width for the container we're in */
  padding: 1.75em;
  background-color: #f5f5f5;
}

.Error {
  max-width: 750px; /* approximately Readme.com's max width for the container we're in */
  padding: 1.75em;
  font-size: 0.9rem;
  background-color: rgba(253, 230, 138, 1);
}

.Error > p {
  max-width: 50ch;
  font-size: 0.8rem;
}
