.URLInput__input {
  padding: .5rem .75rem;
  width: 100%;
  max-width: 45ch;
  border: 1px solid gray;
  border-radius: 5px;
}

.URLInput__input--invalid {
  border-color: red;
}

.URLInput__input--invalid:focus {
  outline-color: red;
}

.URLInput__message {
  font-size: .8rem;
  margin-left: .5rem;
  margin-top: .3rem;
  color: red;
}
