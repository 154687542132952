.InputForm {
  margin-right: 2rem;
}

.InputFormItem {
  padding-bottom: 2.5rem;
}

.InputFormItem__title {
  font-weight: bold;
  padding-bottom: .2rem;
}

.InputFormItem__label {
  display: block;
  color: #555;
  padding-bottom: .5rem;
  font-size: .8rem;
  max-width: 50ch;
  line-height: 1.15;
}

.InputForm__submit {
  color: white;
  background-color: #0077ff;
}

.InputForm__submit:disabled {
  color: white;
  background-color: lightgray;
}
