.Result {
  margin-bottom: 2rem;
  min-height: 12rem; /* the result section has less content, this makes the container resizing less jarring */
}

.Result__label {
  display: block;
  color: #555;
  padding-top: .5rem;
  padding-left: .2rem;
  font-size: .75rem;
  max-width: 50ch;
  line-height: 1.15;
}

.Result__title {
  font-weight: bold;
  padding-bottom: 1rem;
}

.Result__value {
  font-size: .85rem;
  font-family: monospace;
  word-break: break-all;
  cursor: pointer;
  padding: .25rem;
  outline: 0px;
}

/* I apologize for the :not(), this is here because the :hover psuedoclass gets
precedence over the --copied class below */
.Result__value:hover:not(.Result__value--copied) {
  background-color: rgb(173, 173, 173);
  color: white;
}

.Result__value--copied {
  background-color: rgb(52, 211, 153);
  color: white;
}
