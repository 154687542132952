.SelectInput__option {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SelectInput__option > input {
  margin-right: .5rem;
}

.SelectInput__option > label {
  font-size: .95rem;
}
